<template>
  <b-form @submit.prevent="save">
    <div class="title-actions">
      <h5 class="title">{{$t('myNews')}} - {{title}}</h5>
      <div class="btns">
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr>
    <div class="form-group">
      <label for="title">{{$t('title')}}</label>
      <b-form-input v-validate="'required|max:125'" name="title" id="title" v-model="notice.title" :state="validateState('title')" />
      <span class="error-inputs">{{ errors.first('title') }}</span>
    </div>
    <div class="form-group">
      <label for="content">{{$t('content')}}</label>
      <quill-editor ref="myTextEditor" v-model="notice.content" :options="editorOption"></quill-editor>
    </div>
    <div class="form-group">
      <label for="file">{{$t('cover')}}</label>
      <b-form-file v-model="file" :lang="lang" :placeholder="$t('findImage')" accept="image/jpeg" @change="imagePreview" />
      <img v-if="imgPath" :src="imgPath" :alt="notice.title" class="img-thumbnail mt-3">
    </div>
    <div class="form-group">
      <b-form-checkbox switch v-model="notice.show" name="check-button">{{$t('show')}}</b-form-checkbox>
    </div>
    <b-button type="submit" variant="primary" :disabled="loading">{{$t('save')}}</b-button>
    <b-button class="ml-3" @click="$router.go(-1)">{{$t('return')}}</b-button>
  </b-form>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      file: null,
      imgPath: '',
      title: '',
      editorOption: {
        modules: {
          toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{ 'header': 1 }, { 'header': 2 }],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'script': 'sub' }, { 'script': 'super' }],
              [{ 'indent': '-1' }, { 'indent': '+1' }],
              [{ 'direction': 'rtl' }],
              [{ 'size': ['small', false, 'large', 'huge'] }],
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              [{ 'font': [] }],
              [{ 'color': [] }, { 'background': [] }],
              [{ 'align': [] }],
              ['clean'],
              ['link', 'image', 'video']
            ],
           imageDrop: true,
           imageResize: true
         },
         theme: 'snow'
       },
       console: this.$route.params.console
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'notices',
      'notice'
    ])
  },
  mounted() {
    this.changeTitleBar(this.$t('myNews'))
    const noticeId = this.$route.params.noticeId
    if (!noticeId) {
      this.imgPath = ''
      this.title = this.$t('create')
    } else {
      this.imgPath = this.notice.image
      this.title = this.$t('edit')
    }
    // if (this.notices.length < 1) {
    //   this.$router.push({ name: 'NoticeUserList', params: { lang: this.lang } })
    // }
  },
  methods: {
    save () {
      const noticeId = this.$route.params.noticeId
      let payload = new FormData()
      if (this.file) {
        payload.append('cover', this.file)
      }
      payload.append('title', this.notice.title)
      payload.append('content', this.notice.content)
      payload.append('show', this.notice.show ? 1 : 0)
      if (!noticeId) {
        const path = `auth/${this.lang}/${this.console}/notices/user/store`
        this.$axios.post(path, payload).then((response) => {
          const data = response.data.data
          this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
          this.$store.dispatch('ADD_NOTICE', data)
          this.$router.push({ name: 'NoticeUserList', params: { lang: this.lang } })
        })
      } else {
        const path = `auth/${this.lang}/${this.console}/notices/user/${noticeId}/update`
        this.$axios.post(path, payload).then(() => {
          this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
          this.$store.dispatch('UPDATE_NOTICE', this.notice)
          this.$router.push({ name: 'NoticeUserList', params: { lang: this.lang } })
        })
      }
    },
    imagePreview (e) {
      const input = e.target
      this.file = input.files[0]
      const _self = this
      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = function (e) {
          _self.imgPath = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    }
  }
}
</script>
